// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-html-css-js": () => import("./../../../src/pages/html-css.js" /* webpackChunkName: "component---src-pages-html-css-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-javascript-js": () => import("./../../../src/pages/javascript.js" /* webpackChunkName: "component---src-pages-javascript-js" */),
  "component---src-pages-newyear-2020-js": () => import("./../../../src/pages/newyear/2020.js" /* webpackChunkName: "component---src-pages-newyear-2020-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

